<template>
  <div class="share-user-card">
    <van-sticky>
      <van-nav-bar safe-area-inset-top>
        <template #title>
          <div class="head-title">个人名片</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <main class="main">
      <van-skeleton title avatar :row="5" :loading="loading">
        <div class="personal-info">
          <div class="head-image">
            <img :src="headImg" />
          </div>
          <div class="info">
            <img :src="require('../../../assets/user-card.png')" alt="" />
            <div style="position: absolute">
              <div class="name">
                {{ cardInfo.name }}
              </div>
              <div class="title des">
                {{ cardInfo.roleName }}
              </div>
              <div class="phone des">
                <icon-svg iconClass="iconsmartphone1" />
                {{ cardInfo.contactNum }}
              </div>
              <div class="company des">
                <icon-svg iconClass="iconbox" />
                {{ cardInfo.merchantName }}
              </div>
            </div>
          </div>
        </div>
        <div class="btns-box">
          <van-button
            plain
            type="primary"
            class="custom-btn"
            @click="handleShowShareBack"
            >分享名片
          </van-button>
          <van-button
            v-if="showSavePhone"
            type="primary"
            class="custom-btn left"
            @click="handleSavePhone"
            >存入通讯录
          </van-button>
        </div>
      </van-skeleton>
      <div class="description">
        <van-skeleton title :row="7" :loading="loading">
          <div class="title">自我介绍</div>
          <div>
            <video
                :src="videoUrl"
                v-show="videoUrl"
                loop
                preload="auto"
                playsinline
                webkit-playsinline="true"
                x5-video-player-type="h5-page"
                x5-video-player-fullscreen="true"
                x5-playsinline
                x-webkit-airplay="allow"
                x5-video-orientation="portrait"
                :playOrPause="playOrPause"
                @click="playOrPauseVideo"
                :poster="require('../../../assets/userCard.png')"
            ></video>
            <img
                v-show="videoUrl"
                class="icon_play"
                @click="playvideo"
                :src="require('../../../assets/play.png')"
            />
          </div>
          <div class="content">
            {{ cardInfo.selfIntro?cardInfo.selfIntro:defaultInfo }}
          </div>
        </van-skeleton>
      </div>
      <div class="user-tags">
        <van-skeleton title :row="4" :loading="loading">
          <div class="title">印象标签</div>
          <span
              class="tag-item"
              v-for="(item, index) in impressionLabel"
              :key="index"
          >
          <van-tag type="primary">{{ item }}</van-tag>
        </span>
        </van-skeleton>
      </div>
      <div class="company-desc">
        <span class="company-title">公司介绍</span>
        <span class="compnay-btn" @click="handleLinkToOfficialWebsite"
          >查看官网</span
        >
      </div>
      <div v-if="cardInfo.isSetWebsite === 0">
          <div class="company-info">
            <img :src="compnayLogo" alt="" />
            <div class="company-info-describe">
              <p>{{ websiteBriefVO.merchantName }}</p>
            </div>
          </div>
          <div class="company-introduce">
            <span>{{ websiteBriefVO.merchantProfile }}</span>
          </div>
        </div>
      <div class="title case">我的推荐</div>
      <!-- <case-item
        v-for="(item, index) in cardInfo.myCaseList"
        :key="index"
        :caseInfo="item"
        :isShared="true"
        @viewCase="handleLinkToCase(item)"
      /> -->
      <!-- <recommend-Item
      :isShared="true"
      v-for="item in myRecommendList"
      :key="item.id"
      :caseInfo="item"
      @viewCase="handleLinkToCase(item)">

      </recommend-Item> -->
       <recommend-Item
       :isShared="true"
       v-for="item in myRecommendList"
       :key="item.id"
       :recommendInfo="item"
        @viewRecommend="handleLinkToRecommend(item)"
      >
      </recommend-Item>
      <div class="more-recomond" v-if="showMoreRecommend" @click="loadMoreRecommend">更多推荐 <icon-svg  iconClass="iconchevron-down" /></div>
      <div class="recommend">
        <div class="title">我的照片</div>
        <img
          v-for="item in userPics"
          :key="item"
          :src="item.url"
          style="object-fit: cover"
        />
      </div>
      <div class="share-back" v-if="showShareBack">
        <img
          :src="require('@/assets/back.png')"
          @click="showShareBack = false"
        />
      </div>
    </main>

    <van-tabbar
      active-color="#3366FF"
      safe-area-inset-bottom
      @change="handleTabbarChange"
    >
      <van-tabbar-item name="phone" icon="phone">
        <span>电话联系</span>
        <template #icon>
          <icon-svg iconClass="iconsmartphone1" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="info" icon="circle">
        <span>我要咨询</span>
        <template #icon>
          <icon-svg iconClass="iconmessage-square" />
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <van-dialog
      v-model:show="showPhone"
      show-cancel-button
      confirm-button-color="#333333"
      cancel-button-color="#999999"
      @confirm="handleCall"
    >
      <div class="phone-box">
        拨打<span>{{ cardInfo.contactNum }}</span>
      </div>
    </van-dialog>
    <van-dialog
      title="我的咨询"
      v-model:show="showInfo"
      show-cancel-button
      confirm-button-color="#333333"
      cancel-button-color="#999999"
      confirm-button-text="保存"
      cancel-button-text="取消"
      @confirm="handleAddInfo"
      :before-close="beforeClose"
    >
      <div class="info-box">
        <van-form
          ref="addInfoForm"
          input-align="left"
          label-align="left"
          error-message-align="right"
        >
          <van-field
            v-model="addInfo.username"
            label="姓名"
            :rules="[{ required: true, message: '请输入名称' }]"
          />
          <van-field
            v-model="addInfo.phone"
            label="联系电话"
            :rules="[{ required: true, message: '请输入联系电话' }]"
          />
          <van-field
            v-model="addInfo.content"
            type="textarea"
            label="留言"
            :rules="[{ required: true, message: '请输入留言' }]"
          />
        </van-form>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, onUnmounted, reactive, ref, toRefs } from "vue";
import notfound from "@/assets/head-img-not-found.png";
import { createConsult, getUserCard,getRecommend } from "@/api/user/userCard";
import { Dialog, Toast } from "vant";
// import caseItem from "@/components/caseItem.vue";
import recommendItem from "../../../components/recommendItem"
import { useWxAuthorization } from "@/hooks/wxAuthorization";
import $ from "jquery";
import { searchFileList } from "@/api/file";
// import {getWxJsSign} from '@/api/common'
import { initWechatShare } from "@/hooks/initWechatShare";
import { addClue, addClueBehaviorInfo } from "../../../api/user/clue";
export default {
  components: {
    // caseItem,
    recommendItem
  },
  setup() {
    onUnmounted(() => {
      state.playOrPause = true;
    });
    let internal = null;
    let seconds = 0;
    let addInfoForm = ref(null);
    const router = useRouter();
    const route = useRoute();
    const merchantId = route.params.merchantId?route.params.merchantId:localStorage.getItem('merchantId');
    const recommendClueId = route.params.recommendClueId?route.params.recommendClueId:''
    const state = reactive({
      showMoreRecommend:true,
      myRecommendList:[],
      current:1,
      userId:'',
      defaultInfo:'我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！',
      defaultLogo: require("../../../assets/images/company-logo-default.png"),
      compnayLogo:'',
      openId: null,
      consultId: null,
      websiteBriefVO:{},
      cardInfo: {},
      iconPlayShow: true,
      playOrPause: false,
      showSavePhone: false,
      showShareBack: false,
      loading: true,
      videoUrl: "",
      userPics: [],
      addInfo: {
        busiId: "",
        phone: "",
        username: "",
        content: "",
      },
      userInfo: {},
      showInfo: false,
      showPhone: false,
      clueId: "",
    });
    const handleReturn = () => {
      router.back();
    };
    let headImg = computed(() => {
      if (state.cardInfo.headImgUrl) {
        return `${process.env.VUE_APP_WATCH_FILE_API}/${state.cardInfo.headImgUrl}`;
      } else {
        return require('../../../assets/images/default-headImg.gif');
      }
    });
    let impressionLabel = computed(() => {
      if (state.cardInfo.impressionLabel) {
        return state.cardInfo.impressionLabel.split(",");
      } else {
        return ["专业","热情"];
      }
    });
    const playvideo = () => {
      router.push(`/videoPlay/${state.cardInfo.cardId}`);
    };
    const playOrPauseVideo = () => {
      router.push(`/videoPlay/${state.cardInfo.cardId}`);
    };
    const handleSavePhone = () => {
      if (state.cardInfo.contactNum) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: `/pages/other_page/otherPage?firstName=${state.cardInfo.name}&phone=${state.cardInfo.contactNum}`,
        });
        countUserAction({
          openId: state.openId,
          clueId: state.clueId,
          type: 3,
        });
      } else {
        Dialog.alert({ title: "错误", message: "没有电话号码" });
      }
    };
    const handleShowShareBack = () => {
      state.showShareBack = true;
      let title = state.cardInfo.name
        ? `您好，我是${state.cardInfo.name}，期待为您服务!`
        : "个人名片";
      // eslint-disable-next-line no-undef
      wx.miniProgram.postMessage({
        data: {
          title: title,
          img: (state.cardInfo.headImgUrl&&state.cardInfo.smallHeadImgUrl&&!state.isGif)?`${process.env.VUE_APP_WATCH_FILE_API}/${state.cardInfo.smallHeadImgUrl}`:"https://wca.ujiaku.com/upload/wx/wx_delivery_info.png",
          openId: state.openId,
          clueId: state.clueId,
          type: 4,
        },
      });
    };
    const handleToShare = () => {};
    const loadUserCardInfo = async () => {
      try {
        Toast.loading({
          forbidClick: true,
          message: "加载中",
        });

        let data = await getUserCard();
        if (data) {
          state.cardInfo = data;
          state.websiteBriefVO = data.websiteBriefVO;
          state.compnayLogo = data.websiteBriefVO.logoUrl?`${process.env.VUE_APP_WATCH_FILE_API}/${data.websiteBriefVO.logoUrl}`:state.defaultLogo
          state.cardInfo.name = data.createId?data.name:data.defaultName
          state.cardInfo.contactNum = data.createId?data.contactNum:data.defaultContactNum
          state.cardInfo.selfIntro = data.createId?data.selfIntro:"我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！"
          state.cardInfo.impressionLabel = data.createId?data.impressionLabel:"专业,热情"
          state.userId = data.createId
          let allRecommend = await getRecommend({loginUserId:data.createId})
          state.myRecommendList = allRecommend.records
          if(state.myRecommendList.length === allRecommend.total){
            state.showMoreRecommend = false
          }
        }
        let video = await searchFileList({
          busiId: state.cardInfo.cardId,
          busiCat: "user",
          groupId: "descVideo",
        });
        state.loading = false;
        if (video.length) {
          console.log("video", video);
          state.videoUrl = `${process.env.VUE_APP_WATCH_FILE_API}/${video[0].url}`;
        }
        let userPics = await searchFileList({
          busiId: state.cardInfo.cardId,
          busiCat: "user",
          groupId: "userPics",
        });
        if (userPics.length) {
          state.userPics = userPics.map((item) => {
            item.url = `${process.env.VUE_APP_WATCH_FILE_API}/${item.url}`;
            return item;
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        Toast.clear();
      }
    };

    const loadMoreRecommend = async()=>{
      state.current =  state.current+1
      let itemList = await getRecommend({loginUserId:state.userId,current:state.current})
      state.myRecommendList.push(...itemList.records)
      if(state.myRecommendList.length === itemList.total){
        state.showMoreRecommend = false
      }
    }
    const handleLinkToRecommend = (item) => {
      let miniProgramPhone = route.query.state
        ? route.query.state
        : route.query.miniProgramPhone;
      let clueShareSource = route.params.clueShareSource?route.params.clueShareSource:1
      if(item.contentType===3){
        router.push({
        path: `/caseShare/${item.recommendId}/${clueShareSource}/${merchantId}/${recommendClueId}`,
        query: { miniProgramPhone: miniProgramPhone, openId: state.openId },
       });
      }else if(item.contentType===1){
        router.push({
        path: `/weChatShare/${item.recommendId}/${clueShareSource}/${merchantId}/${recommendClueId}`,
        query: { miniProgramPhone: miniProgramPhone, openId: state.openId },
       });
      }else if(item.contentType===4){
        console.log("视频=============",item);
        router.push({
        path: `/video/videoDetail/${item.recommendId}/${state.userId}/${clueShareSource}/${merchantId}/${recommendClueId}`,
        query: { miniProgramPhone: miniProgramPhone, openId: state.openId },
       });
      }else if (item.contentType===5){
        router.push({
          path: `/buildSiteShare/${item.recommendId}/${clueShareSource}/${merchantId}`,
          query: { miniProgramPhone: miniProgramPhone, openId: state.openId },
        });
      }
    };
    // const handleLinkToCase = (item) => {
    //   console.log(item);
    //   let miniProgramPhone = route.query.state
    //     ? route.query.state
    //     : route.query.miniProgramPhone;
    //   // alert(route.params.clueShareSource)
    //   let clueShareSource = route.params.clueShareSource?route.params.clueShareSource:1
    //   router.push({
    //     path: `/caseShare/${item.caseId}/${clueShareSource}/${merchantId}`,
    //     query: { miniProgramPhone: miniProgramPhone, openId: state.openId },
    //   });
    // };
    const handleLinkToOfficialWebsite = () => {
      if(state.cardInfo.isInitWebsite){
        if (state.cardInfo.isSetWebsite) {
        if (state.cardInfo.officialWebsite) {
          router.push({
            name: "panoramicWindow",
            query: { panoramicUrl: state.cardInfo.officialWebsite },
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "请先前往优家宝web进行官网设置。",
          });
        }
      } else {
        let miniProgramPhone = route.query.state
          ? route.query.state
          : route.query.miniProgramPhone;
        router.push({
          path: `/microWebsiteShare/${route.params.clueShareSource}/${merchantId}/${recommendClueId}`,
          query: { miniProgramPhone: miniProgramPhone, openId: state.openId },
        });
      }
      }else{
        Dialog.alert({
            title: "提示",
            message: "该用户未设置微官网。",
          });
      }

    };

    const handleTabbarChange = (name) => {
      if (name == "info") {
        state.showInfo = true;
      } else if (name == "phone") {
        // state.showPhone = true;
        if (state.cardInfo.contactNum) {
          let a = document.createElement("a");
          a.setAttribute("href", `tel:${state.cardInfo.contactNum}`);
          a.click();
          countUserAction({
            openId: state.openId,
            clueId: state.clueId,
            type: 2,
          });
        } else {
          Dialog.alert({ title: "错误", message: "没有电话号码" });
        }
      }
    };
    const handleCall = () => {
      if (state.cardInfo.contactNum) {
        let a = document.createElement("a");
        a.setAttribute("href", `tel:${state.cardInfo.contactNum}`);
        a.click();
        countUserAction({
          openId: state.openId,
          clueId: state.clueId,
          type: 2,
        });
      } else {
        Dialog.alert({ title: "错误", message: "没有电话号码" });
      }
    };
    const useWxAuth = async () => {
      let data = await useWxAuthorization();
      if (data) {
        state.userInfo = data;
        state.openId = data.openId;
      } else {
        state.openId = route.query.openId;
      }

      await loadUserCardInfo();
      let clueId = await handleFirstAddClue();
      let title = state.cardInfo.name
        ? `您好，我是${state.cardInfo.name}，期待为您服务!`
        : "个人名片";
      let temp = location.href.split("?")[0];
      if(recommendClueId){
       temp =temp.replace(recommendClueId,clueId)
      }else {
        temp = `${temp}/${clueId}`
      }
      // eslint-disable-next-line no-undef
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          state.showSavePhone = true;
          // eslint-disable-next-line no-undef
          wx.miniProgram.postMessage({
            data: {
              title: title,
              img: (state.cardInfo.headImgUrl&&state.cardInfo.smallHeadImgUrl&&!state.isGif)?`${process.env.VUE_APP_WATCH_FILE_API}/${state.cardInfo.smallHeadImgUrl}`:"https://wca.ujiaku.com/upload/wx/wx_delivery_info.png",
              openId: state.openId,
              clueId: state.clueId,
              shareUrl:temp,
              type: 4,
            },
          });
        } else {
          let title = state.cardInfo.name
            ? `您好，我是${state.cardInfo.name}，期待为您服务!`
            : "个人名片";
          let desc = state.cardInfo.selfIntro
            ? `${state.cardInfo.selfIntro}`
            : "个人介绍";
          let appPhone;
          if (localStorage.getItem("appPhone")) {
            appPhone = localStorage.getItem("appPhone")
          } else {
            appPhone = route.query.appPhone
          }
          let url = `${
            process.env.VUE_APP_WECHAT_SHARE
          }/shareForward?shareUrl=${temp}?appPhone=${appPhone}`;
          let imgUrl = (state.cardInfo.headImgUrl&&state.cardInfo.smallHeadImgUrl&&!state.isGif)?`${process.env.VUE_APP_WATCH_FILE_API}/${state.cardInfo.smallHeadImgUrl}`:"https://wca.ujiaku.com/upload/wx/wx_delivery_info.png";
          initWechatShare({
            title,
            desc,
            url,
            imgUrl,
            openId: state.openId,
            clueId: state.clueId,
            type: 4,
          });
        }
      });
      countUserAction({ openId: state.openId, clueId: clueId, type: 1 });
    };
    useWxAuth();
    // useWxAuthorization().then((data) => {
    //   if (data) {
    //     state.userInfo = data;
    //     state.openId = data.openId;
    //   } else {
    //     state.openId = route.query.openId;
    //   }
    //   if (state.openId) {
    //     loadUserCardInfo().then(() => {
    //       handleFirstAddClue().then((data) => {
    //         alert(data);

    //       });
    //     });
    //   }
    // });
    const handleAddClue = () => {
      if (seconds > 0) {
        let miniProgramPhone = route.query.state
          ? route.query.state
          : route.query.miniProgramPhone;
        let param = {
          browseSecond: seconds,
          consultId: state.consultId,
          clueId: state.clueId,
          shareId: state.cardInfo.cardId,
          clueSource: 4,
          beSharePhone: miniProgramPhone,
          openId: state.openId,
          name: state.cardInfo.name,
          createId: state.cardInfo.createId,
          clueShareSource: route.params.clueShareSource,
          recommend_Clue_Id:recommendClueId,
          merchantId
        };
        $.ajax({
          async: false,
          url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
          type: "post",
          contentType: "application/json;charset=UTF-8",
          data: JSON.stringify(param),
        });
      }
      seconds = 0;
      clearInterval(internal);
    };
    const handleWindowHide = () => {
      if (seconds > 0) {
        let miniProgramPhone = route.query.state
          ? route.query.state
          : route.query.miniProgramPhone;
        let param = {
          browseSecond: seconds,
          consultId: state.consultId,
          clueId: state.clueId,
          shareId: state.cardInfo.cardId,
          clueSource: 4,
          beSharePhone: miniProgramPhone,
          openId: state.openId,
          name: state.cardInfo.name,
          createId: state.cardInfo.createId,
          clueShareSource: route.params.clueShareSource,
          recommend_Clue_Id:recommendClueId,
          merchantId
        };
        let blob = new Blob([JSON.stringify(param)], {
          type: "application/json",
        });

        navigator.sendBeacon(
          `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
          blob
        );
        seconds = 0;
        clearInterval(internal);
      }
    };
    const handleFirstAddClue = async () => {
      let miniProgramPhone = route.query.state
        ? route.query.state
        : route.query.miniProgramPhone;
      let param = {
        shareId: state.cardInfo.cardId,
        clueSource: 4,
        browseSecond: seconds,
        beSharePhone: miniProgramPhone,
        openId: state.openId,
        name: state.cardInfo.name,
        createId: state.cardInfo.createId,
        consultId: state.consultId,
        clueShareSource: route.params.clueShareSource,
        recommend_Clue_Id:recommendClueId,
        merchantId
      };
      let data = await addClue(param);
      state.clueId = data;
      return data;
      // $.ajax({
      //     async: false,
      //     url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
      //     type: "post",
      //     contentType: "application/json;charset=UTF-8",
      //     data: JSON.stringify(param),
      //     success: (data) => {
      //         if (data) {
      //             state.clueId = data;
      //             return data
      //         }
      //     },
      // });
    };
    const handleVisibilityStateChange = () => {
      if (document.visibilityState === "hidden") {
        handleAddClue();
        seconds = 0;
        clearInterval(internal);
      }
    };
    const countUserAction = async ({ clueId, openId, type }) => {
      /**
       * type:
       *  1查看
       *  2拨打
       *  3保存
       *  4转发
       *  5提交咨询
       *  6点赞
       *  7收藏
       *  8留言
       *  9提交装修报价
       */

      if (clueId) {
        await addClueBehaviorInfo({ clueId, openId, type });
      }
    };
    onMounted(() => {
      internal = setInterval(() => {
        seconds++;
      }, 1000);
      document.addEventListener(
        "visibilitychange",
        handleVisibilityStateChange
      );
      window.addEventListener("pagehide", handleWindowHide, false);
    });
    onUnmounted(() => {
      clearInterval(internal);
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityStateChange
      );
      window.removeEventListener("pagehide", handleWindowHide, false);
      handleAddClue();
    });
    const beforeClose = (action) => {
      if (action === "confirm") {
        return false;
      } else {
        // 重置表单校验
        addInfoForm.value.resetValidation();
        return true;
      }
    };
    const handleAddInfo = () => {
      state.addInfo.busiId = state.cardInfo.cardId;
      let param = {
        ...state.addInfo,
      };
      addInfoForm.value.validate().then(() => {
        createConsult(param).then((data) => {
          if (data) {
            Dialog.alert({ title: "成功", message: "您的咨询已创建" });
            state.consultId = data.id;
            state.showInfo = false;
            countUserAction({
              openId: state.openId,
              clueId: state.clueId,
              type: 5,
            });
          }
        });
      });
    };

    return {
      ...toRefs(state),
      handleReturn,
      handleLinkToOfficialWebsite,
      notfound,
      headImg,
      handleTabbarChange,
      handleCall,
      handleAddInfo,
      impressionLabel,
      addInfoForm,
      beforeClose,
      // handleLinkToCase,
      playvideo,
      playOrPauseVideo,
      handleSavePhone,
      handleToShare,
      handleShowShareBack,
      loadMoreRecommend,
      handleLinkToRecommend,
    };
  },
};
</script>
<style lang="scss">
.info-box {
  .van-field__label {
    width: 60px;
    font-weight: 600;
    font-size: 14px;
  }

  .van-cell__value,
  .van-field__value {
    border: 1px solid #bfbfbf;
  }
}
</style>

<style lang="scss" scoped>
.share-user-card {
  margin-bottom: 50px;
  text-align: left;

  .case {
    padding: 8px 16px 0 16px;
  }
  .more-recomond{
      height: 35px;
      font-size: 14px;
      line-height: 35px;
      text-align: center;
      background-color: #E5E5E5;
      color: #9999;
      border-color: none;
    }

  .recommend {
    border-top: 2px solid #f1f3f6;
    padding: 8px 16px;

    img {
      vertical-align: middle;
      width: 100%;
      border-radius: 8px;
    }
  }

  .phone-box {
    height: 50px;
    line-height: 50px;
    text-align: center;

    span {
      color: #3366ff;
    }
  }

  .info-box {
    padding: 10px;

    input {
      border: 1px solid red;
    }
  }

  .head-title {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
  }

  .header-btn-right {
    color: #ff6e01;
  }

  .btns-box {
    margin: 24px 16px;

    .custom-btn {
      width: 167px;
      height: 40px;
      border-radius: 4px;
    }

    .left {
      margin-left: 8px;
    }
  }

  .personal-info {
    height: 180px;
    word-break: break-all;
    margin: 16px;
    box-sizing: border-box;
    box-shadow: 5px 10px 10px #3333;
    padding-bottom: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    box-sizing: content-box;
    min-width: 64px;
    display: flex;
    margin-bottom: 3px;
    padding: 0px;
    border-radius: 5px;
    position: relative;

    .head-image {
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      img {
        width: 145px;
        height: 100%;
        object-fit: cover;
        vertical-align: bottom;
        align-items: flex-end;
        border-radius: 4px;
        border-right: 15px solid transparent;
      }
    }

    .info {
      text-align: left;
      flex: 1;

      img {
        position: absolute;
        left: 130px;
        width: 210px;
        height: 100%;
      }

      .des {
        color: #666666;
        font-weight: 600;
        font-size: 11px;
      }

      .z-big {
        z-index: 99;
      }

      .name {
        font-size: 24px;
        color: #333333;
        margin-top: 37px;
        font-weight: 600;
      }

      .title {
        margin-top: 4px;
      }

      .phone {
        margin-top: 16px;
      }

      .role {
        font-weight: 400;
        margin-top: 8px;
      }

      .company {
        margin-top: 4px;
        font-weight: 600;
      }
    }

    .icon-right {
      align-self: flex-end;
      color: #289fff;
    }
  }

  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
  }

  .description {
    border-top: 3px solid #f1f3f6;
    position: relative;
    border-bottom: 3px solid #f1f3f6;
    padding: 16px;

    video {
      width: 100%;
      height: 200px;
    }

    .icon_play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 60px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
    }

    .content {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
  }

  .user-tags {
    padding: 16px;
    border-bottom: 3px solid #f1f3f6;

    .tag-item {
      margin-right: 3px;

      .van-tag {
        padding: 3px 8px;
      }
    }
  }

  .company-desc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #666666;
    height: 24px;
    padding: 8px 0;
    border-bottom: 3px solid #f1f3f6;
    padding: 16px;

    span {
      display: inline-block;
    }

    .company-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .compnay-btn {
      font-style: normal;
      color: #289fff;
      font-weight: 600;
      line-height: 22px;
      font-size: 12px;
    }
  }
    .company-info {
    // height: 41px;
    background-color: #fff;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    > img {
      height: 40px;
      width: 40px;
      border-radius: 20px;
    }
    .company-info-describe {
      margin-left: 8px;
      > p {
        margin: 0;
        padding: 0;
      }
      > :nth-child(1) {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .company-introduce {
      font-weight: 400;
      padding: 0 16px;
      font-size: 14px;
      color: #6666;
      background-color: #fff;
    }

  .cases {
    padding: 16px;
    border-bottom: 3px solid #f1f3f6;

    .case-item {
      img {
        width: 100%;
        height: 160px;
      }

      .case-title {
        font-weight: 600;
        font-size: 16px;
        color: #333333;
      }

      .other-info {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
    }
  }

  .bottom-btns {
    width: 100%;
    position: fixed;
    bottom: 0px;

    button {
      height: 50px;
      color: #fff;
      box-sizing: border-box;
    }

    button:nth-child(1) {
      background-color: #fc9834;
    }

    button:nth-child(2) {
      background-color: #ff6e01;
    }
  }
}

.share-back {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
